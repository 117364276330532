<template>
    <div class="hate_modal" @click="$emit('closehate')">
        <div class="center">
            <img class="thumb" :class="active" src="/media/img/big_hate_icon.png">
            <div class="circle-wrap">
                <div class="circle-lg" :class="active"></div>
            </div>
            <div class="dots-wrap">
                <div class="dot dot--t" :class="active"></div>
                <div class="dot dot--tr" :class="active"></div>
                <div class="dot dot--br" :class="active"></div>
                <div class="dot dot--b" :class="active"></div>
                <div class="dot dot--bl" :class="active"></div>
                <div class="dot dot--tl" :class="active"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  emits: ["closehate"],
  props: {
    active: {
      type: String,
      required: false,
      default: ""
    }
  },
  setup () { },
  mounted () {
    setTimeout(() => {
      this.$emit("closehate");
    }, 5000);
  }
});
</script>

<style scoped>
.hate_modal {
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
  max-width: 120px;
}

.center {
  width: 100%;
}

.thumb {
  transform: scale(0);
  width: 120px;
}

.thumb.on {
  animation: scale 1.5s
    /*infinite*/
    ease-in-out forwards;
  animation-delay: 0.2s;
}

@keyframes scale {
  0% {
    transform: scale(0);
  }

  1% {
    transform: scale(0);
  }

  20% {
    transform: scale(0);
  }

  35% {
    transform: scale(1.4);
  }

  40% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  65% {
    transform: scale(1);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.circle-wrap {
  position: relative;
  width: 180px;
  height: 180px;
  z-index: -1;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
}

.circle-lg {
  position: absolute;
  top: 10px !important;
  left: 0 !important;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border-style: solid;
  border-color: rgba(255, 45, 85, 0.3);
  opacity: 0;
}

.circle-lg.on {
  animation: scaleCircle 1.5s
    /*infinite*/
    ease-in-out forwards;
  animation-delay: 0.2s;
}

@keyframes scaleCircle {
  0% {
    border-width: 90px;
    transform: scale(0);
  }

  15% {
    border-color: rgba(230, 128, 124, 0.3);
    border-width: 90px;
  }

  30% {
    border-color: rgba(230, 95, 91, 0.3);
    opacity: 1;
  }

  35% {
    border-color: rgba(255, 45, 85, 0.4);
  }

  50% {
    border-width: 0;
    transform: scale(1);
    opacity: 0.3;
  }

  100% {
    border-width: 0;
    transform: scale(1);
    opacity: 0;
  }
}

.dots-wrap {
  transform: translate(-50%, -40%);
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
  width: 220px;
  height: 220px;
}

.dot {
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background: #00CCFF;
  margin: 0 auto;
  top: 105px;
  left: 0;
  right: 0;
  opacity: 0;
}

.dot--t {
  background: #FF8991;
}

.dot--t.on {
  animation: slideUp 1.5s
    /*infinite*/
    ease-in-out forwards;
  animation-delay: 0.2s;
}

@keyframes slideUp {
  0% {
    transform: translateY(0) scale(1);
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  55% {
    transform: translateY(-125px) scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(-125px) scale(0);
  }
}

.dot--tr {
  background: #FD4E6D;
}

.dot--tr.on {
  animation: slideUpRight 1.5s
    /*infinite*/
    ease-in-out forwards;
  animation-delay: 0.2s;
}

@keyframes slideUpRight {
  0% {
    transform: translate(0) scale(1);
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  55% {
    transform: translate(125px, -80px) scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(125px, -80px) scale(0);
  }
}

.dot--br {
  background: #FF8991;
}

.dot--br.on {
  animation: slideDownRight 1.5s
    /*infinite*/
    ease-in-out forwards;
  animation-delay: 0.2s;
}

@keyframes slideDownRight {
  0% {
    transform: translate(0) scale(1);
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  55% {
    transform: translate(125px, 80px) scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(125px, 80px) scale(0);
  }
}

.dot--b {
  background: #FD4E6D;
}

.dot--b.on {
  animation: slideDown 1.5s
    /*infinite*/
    ease-in-out forwards;
  animation-delay: 0.2s;
}

@keyframes slideDown {
  0% {
    transform: translateY(0) scale(1);
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  55% {
    transform: translateY(125px) scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(125px) scale(0);
  }
}

.dot--bl {
  background: #FF8991;
}

.dot--bl.on {
  animation: slideDownLeft 1.5s
    /*infinite*/
    ease-in-out forwards;
  animation-delay: 0.2s;
}

@keyframes slideDownLeft {
  0% {
    transform: translate(0) scale(1);
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  55% {
    transform: translate(-125px, 80px) scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(-125px, 80px) scale(0);
  }
}

.dot--tl {
  background: #FD4E6D;
}

.dot--tl.on {
  animation: slideUpLeft 1.5s
    /*infinite*/
    ease-in-out forwards;
  animation-delay: 0.2s;
}

@keyframes slideUpLeft {
  0% {
    transform: translate(0) scale(1);
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  55% {
    transform: translate(-125px, -80px) scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(-125px, -80px) scale(0);
  }
}

.hate_modal img {
  margin-top: 30px;
}
</style>
